import { prismic } from "../site-config";
import { linkResolver } from "./prismic-link-resolver";

export const repositoryConfigs = [
	{
		accessToken: prismic.accessToken,
		repositoryName: prismic.repo,
		linkResolver,
	},
];
