import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from './src/utils/prismic-previews'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AnimatePresence } from "framer-motion"

const transitionDelay = 500;

export const wrapPageElement = ({element}) => (
    <AnimatePresence initial={false} mode="wait">{element}</AnimatePresence>
);

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay*1.5
        );
    }
    return false;
};

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
    </PrismicPreviewProvider>
)

export const onInitialClientRender = () => {
    setTimeout(() => {
        if (document.getElementById("___loader")) {
            document.getElementById("___gatsby").classList.add("is-loader");

            setTimeout(function () {
                window.scrollTo(0, 0);
                
                document.getElementById("___loader").classList.add("is-loaded");
            }, 1250)

            setTimeout(() => {
                document.getElementById("___gatsby").classList.remove("is-loader");
                document.getElementById("___gatsby").classList.add("is-loaded");
                document.getElementById("___loader").style.display = "none"
                ScrollTrigger.refresh(true);
            }, 4000);
        }
        else {
            document.getElementById("___gatsby").classList.add("is-loaded");
        }
    }, 0);
}