module.exports = {
    siteMetadata: {
      title: `Two Create Studio`,
      description: `London based design studio, Two Create specialises in branding, product design and packaging design.`,
      author: `@twocreatestudio`,
      siteUrl: process.env.SITE_URL,
      twitterHandle: '@twocreatestudio'
    },
    prismic: {
      accessToken: process.env.PRISMIC_ACCESS_TOKEN,
      repo: process.env.GATSBY_PRISMIC_REPO_NAME
    },
}